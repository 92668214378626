import { graphql } from '../gql';

export const GET_COUPON = graphql(`
    query getCoupon($coupon_code: String!) {
        getCoupon(coupon_code: $coupon_code) {
            name
            id
            coupon_code
            active_to
            actions {
                discount_type
                discount_value
                min_required_order_value
                max_order_usage_count
            }
        }
    }
`);

export const CHECK_USER_LOGGED_IN = graphql(`
    query checkUserLoggedIn {
        getUserProfile {
            id
        }
    }
`);

export const GET_USER_ACCOUNT_INFO_DATA = graphql(`
    query getUserAccountInfoData {
        getUserProfile {
            id
            first_name
            last_name
            user_referral_code
            first_delivery
            last_visited_inventory
            refill_schedule {
                id
                delivery_day
            }
            location {
                building_id
                city_id
                google_place_id
                latitude
                longitude
                address_line
                address_line_2
                neighborhood
                city
                state
                postal_code
                delivery_notes
            }
            num_of_kids
            email
            mobile_no
            updatedAt
            createdAt
            active
            travel
            social_id
            signup_type
            num_of_pets
            household
            role {
                id
                name
                createdAt
                updatedAt
            }
            stripe_credit {
                credit_amount
            }
        }
        getUsersAllCard {
            default_card_id
            stripe_customer_id
            card_data
        }
        getUserMembership {
            subscription_id
            status
            end_date
            createdAt
            subscription {
                id
                name
                price
            }
        }
        getUserCouponCodes(exclude_deactivated: true) {
            coupon {
                id
                coupon_code
                name
            }
        }
    }
`);

export const UPDATE_USER = graphql(`
    mutation updateUser(
        $first_name: String!
        $last_name: String!
        $email: String!
        $mobile_no: String!
        $household: Int!
        $num_of_pets: Int!
        $place_id: String!
        $address_line: String!
        $address_line_2: String
        $neighborhood: String
        $city: String!
        $state: String!
        $postal_code: String!
        $latitude: Float!
        $longitude: Float!
        $delivery_notes: String
    ) {
        updateProfile(
            first_name: $first_name
            last_name: $last_name
            email: $email
            mobile_no: $mobile_no
            household: $household
            num_of_pets: $num_of_pets
        ) {
            id
        }
        updateUserAddress(
            place_id: $place_id
            address_line: $address_line
            address_line_2: $address_line_2
            neighborhood: $neighborhood
            city: $city
            state: $state
            postal_code: $postal_code
            latitude: $latitude
            longitude: $longitude
            delivery_notes: $delivery_notes
        ) {
            id
        }
    }
`);

export const ADD_CARD = graphql(`
    mutation addCard($card_id: String!, $card_token: String!) {
        addCard(card_id: $card_id, card_token: $card_token) {
            id
            is_default
        }
    }
`);

export const EDIT_CARD = graphql(`
    mutation editCard(
        $card_id: String!
        $is_default: Boolean
        $name: String
        $exp_month: Int
        $exp_year: Int
    ) {
        editCard(
            card_id: $card_id
            is_default: $is_default
            name: $name
            exp_month: $exp_month
            exp_year: $exp_year
        ) {
            message
        }
    }
`);

export const DELETE_CARD = graphql(`
    mutation deleteCard($card_id: String!) {
        deleteCard(card_id: $card_id) {
            message
        }
    }
`);

export const CHANGE_PASSWORD = graphql(`
    mutation changePassword(
        $old_password: String!
        $new_password: String!
        $confirm_password: String!
    ) {
        changePassword(
            oldPassword: $old_password
            newPassword: $new_password
            confirmPassword: $confirm_password
        ) {
            message
        }
    }
`);

export const GET_CANCEL_DECISION = graphql(`
    query getCancelDecisionFromTypeform(
        $typeform_id: String!
        $response_id: String!
    ) {
        getCancelDecisionFromTypeform(
            typeform_id: $typeform_id
            response_id: $response_id
        ) {
            decision
        }
    }
`);

export const CANCEL_MEMBERSHIP = graphql(`
    mutation cancelSubscription {
        cancelSubscription {
            id
            status
        }
    }
`);

export const START_MEMBERSHIP = graphql(`
    mutation startSubscription {
        startSubscription {
            id
            status
        }
    }
`);

export const CHANGE_MEMBERSHIP = graphql(`
    mutation changeSubscription {
        changeSubscription {
            id
            status
        }
    }
`);

export const PAUSE_MEMBERSHIP = graphql(`
    mutation pauseSubscription($resumes_at: Date!) {
        pauseSubscription(resumes_at: $resumes_at) {
            id
            status
        }
    }
`);

export const ADD_COUPON_CODE = graphql(`
    mutation addUserCouponCode($coupon_code: String!) {
        addUserCouponCode(coupon_code: $coupon_code) {
            id
            user_id
            coupon {
                coupon_code
            }
        }
    }
`);

export const GET_USER_BILLING_PORTAL_SESSION = graphql(`
    query getUserBillingPortalSession($return_url: String) {
        getUserBillingPortalSession(return_url: $return_url) {
            short_live_url
        }
    }
`);

export const GET_USER_ACTIVE_COUPON = graphql(`
    query getUserActiveCoupon($user_id: Int!) {
        getUserActiveCoupon(user_id: $user_id) {
            id
            applied_membership
            order_usage_count
            deactivated_at
            createdAt
            coupon {
                id
                coupon_code
                name
                active_to
                actions {
                    id
                    discount_type
                    discount_value
                    max_order_usage_count
                    min_required_order_value
                }
            }
        }
    }
`);
