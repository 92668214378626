import { useLazyQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import { useAuthContext } from '@contexts/AuthContext';
import { useCostPerUnitDisplayDisabledExperiment } from '@custom-hooks/featureFlag/useCostPerUnitDisplayDisabledExperiment';
import { useNewSortOrder } from '@custom-hooks/featureFlag/useNewSortOrder';
import { SegmentProductsSorted } from '@utilities/analytics';
import sortbyMethodTypes from '@utilities/constants/sortbyMethodTypes';
import { getPreviousDay } from '@utilities/date/getPreviousDay';

import { GET_USER_ACTIVE_COUPON } from '../../queries/account';

export const DashboardContext = createContext();
export const useDashboardContext = () => useContext(DashboardContext);

export const DashboardContextProvider = ({ children }) => {
    const router = useRouter();
    const { enqueueSnackbar } = useSnackbar();
    const { userAccountInfo } = useAuthContext();
    const { shouldUseNewSortOrder } = useNewSortOrder();
    const [user, setUser] = useState(null);
    const [subscription, setSubscription] = useState(null);
    const defaultSortOrder = shouldUseNewSortOrder
        ? sortbyMethodTypes.NEW
        : sortbyMethodTypes.MOST_POPULAR;
    const [sortbyMethod, setSortbyMethod] = useState(defaultSortOrder);

    const [userActiveCoupon, setUserActiveCoupon] = useState(null);
    const { isCostPerUnitDisplayDisabled } =
        useCostPerUnitDisplayDisabledExperiment();
    const [
        isRefillMinimumOrderValueModalVisible,
        setIsRefillMinimumOrderValueModalVisible,
    ] = useState(false);
    const [getUserActiveCoupon, { data: activeCouponData }] = useLazyQuery(
        GET_USER_ACTIVE_COUPON,
        { fetchPolicy: 'cache-first' },
    );

    const handleIncompleteAddress = () => {
        enqueueSnackbar('Please add a valid delivery address.', {
            variant: 'error',
            persist: true,
        });
        router.push('/dashboard/account');
    };

    // This function shows a toast message with a debounce that will prevent the same message
    // from being shown multiple times. This allows for cases where a state change causes multiple
    // hooks to request a toast message to be shown, but we only want to show it once.
    const toastMessageRef = useRef(null);
    const showToast = (message, config) => {
        if (toastMessageRef.current !== message) {
            toastMessageRef.current = message;
            enqueueSnackbar(message, config);
            setTimeout(() => {
                toastMessageRef.current = null;
            }, 1000);
        }
    };

    // Ensure default sort order is (re)set when FF loads
    useEffect(() => {
        setSortbyMethod(defaultSortOrder);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldUseNewSortOrder]);

    useEffect(() => {
        if (activeCouponData?.getUserActiveCoupon) {
            setUserActiveCoupon(activeCouponData.getUserActiveCoupon);
        }
    }, [activeCouponData]);

    useEffect(() => {
        if (userAccountInfo?.getUserMembership?.length) {
            const firstSubscription = userAccountInfo.getUserMembership[0];
            setSubscription(firstSubscription);
        }
        if (userAccountInfo?.getUserProfile) {
            setUser(userAccountInfo.getUserProfile);
        }
        if (userAccountInfo?.getUserProfile.id) {
            getUserActiveCoupon({
                variables: {
                    user_id: userAccountInfo?.getUserProfile?.id,
                },
            });
        }
        if (
            userAccountInfo?.getUserProfile &&
            !userAccountInfo.getUserProfile?.location?.city_id
        ) {
            handleIncompleteAddress();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userAccountInfo]);

    useEffect(() => {
        if (activeCouponData) {
            setUserActiveCoupon(activeCouponData.getUserActiveCoupon);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userActiveCoupon]);

    const handleApplySortbyMethod = useCallback(
        (s) => {
            setSortbyMethod(s);
            SegmentProductsSorted(s);
        },
        [setSortbyMethod],
    );

    const userIsCancelled = useMemo(() => {
        if (!subscription) return false;
        return subscription.status === 'cancel';
    }, [subscription]);

    const userIsPaused = useMemo(() => {
        if (!subscription) return false;
        return subscription.status === 'pause';
    }, [subscription]);

    const cityId = useMemo(() => {
        if (!user) return null;
        return user.location?.city_id ? Number(user.location?.city_id) : null;
    }, [user]);

    const refillDay = useMemo(() => {
        if (!user) return null;
        return user.refill_schedule?.delivery_day;
    }, [user]);

    const lockTime = useMemo(() => {
        if (!user) return null;
        return `${getPreviousDay(refillDay)} at 11am`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refillDay]);

    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const contexts = {
        cityId,
        handleApplySortbyMethod,
        isCostPerUnitDisplayDisabled,
        isRefillMinimumOrderValueModalVisible,
        lockTime,
        refillDay,
        setIsRefillMinimumOrderValueModalVisible,
        showToast,
        sortbyMethod,
        subscription,
        user,
        userActiveCoupon,
        userIsCancelled,
        userIsPaused,
    };

    return (
        <DashboardContext.Provider value={contexts}>
            {children}
        </DashboardContext.Provider>
    );
};
