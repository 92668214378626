import {
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useMemo,
    useState,
} from 'react';

import { useRefillContext } from './RefillsContext';

enum RefillCartState {
    Loading = 'loading',
    Success = 'success',
}

interface RefillCartContextShape {
    refillCart: {
        cartCount?: number;
        close: () => void;
        isOpen: boolean;
        open: () => void;
        orderIntentId?: number;
        refetch?: () => void;
        state: RefillCartState;
    };
}

const emptyDefault: RefillCartContextShape = {
    refillCart: {
        close: () => {},
        isOpen: false,
        open: () => {},
        state: RefillCartState.Loading,
    },
};

const RefillCartContext = createContext<RefillCartContextShape>(emptyDefault);

const useRefillCartContext = () => useContext(RefillCartContext);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RefillCartProvider = ({ children }: { children: ReactNode }) => {
    const refill = useRefillContext();

    const [isOpen, setIsOpen] = useState(false);

    const open = useCallback(() => setIsOpen(true), []);
    const close = useCallback(() => setIsOpen(false), []);

    const contextProviderValue: RefillCartContextShape = useMemo(
        () => ({
            refillCart:
                refill.state === 'complete' && refill.now
                    ? {
                          cartCount: refill.now.itemCount,
                          close,
                          isOpen,
                          open,
                          orderIntentId: refill.now.id,
                          refetch: refill.refresh,
                          state: RefillCartState.Success,
                      }
                    : {
                          close,
                          isOpen,
                          open,
                          state: RefillCartState.Loading,
                      },
        }),
        [close, isOpen, open, refill],
    );

    return (
        <RefillCartContext.Provider value={contextProviderValue}>
            {children}
        </RefillCartContext.Provider>
    );
};

export { RefillCartProvider, useRefillCartContext };
