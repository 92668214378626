import { useApolloClient } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';
import { useRouter } from 'next/router';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { GetUserAccountInfoDataQuery } from 'src/gql/graphql';

import {
    CHECK_USER_LOGGED_IN,
    GET_USER_ACCOUNT_INFO_DATA,
} from '@custom-queries/account';
import { ErrorService } from '@services/error';
import { SegmentIdentify } from '@utilities/analytics';

const AuthContext = React.createContext<{
    userProfile: GetUserAccountInfoDataQuery['getUserProfile'] | null;
    userAccountInfo: GetUserAccountInfoDataQuery | null;
    isLoggedIn: boolean;
    authLoading: boolean;
}>({
    userProfile: null,
    userAccountInfo: null,
    isLoggedIn: false,
    authLoading: false,
});
const AuthDispatchContext = React.createContext({
    refreshUser: () => {},
    refreshUserLoggedOut: () => {},
});

/**
 * The reason we use a separate AuthDispatchContext is if a component
 * only cares about updating the context (via refreshUser or
 * refreshUserLoggedOut), and doesn't care about reading the context, then we
 * don't want that component to re-render any time some other user property is
 * updated.
 */

const useAuthContext = () => useContext(AuthContext);
const useAuthDispatchContext = () => useContext(AuthDispatchContext);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AuthProvider = ({ children }: any) => {
    const [user, setUser] = useState<
        GetUserAccountInfoDataQuery['getUserProfile'] | null
    >(null);
    const [userAccountInfo, setUserAccountInfo] =
        useState<GetUserAccountInfoDataQuery | null>(null);
    const [isLoggedIn, setIsLoggedIn] = useState(() => !!user?.id);
    const [loadingUser, setLoadingUser] = useState(false);
    const [loadingAuth, setLoadingAuth] = useState(true);

    const router = useRouter();
    const client = useApolloClient();

    const authLoading = loadingUser || loadingAuth;

    const clearUser = () => {
        setUser(null);
        setUserAccountInfo(null);
        setIsLoggedIn(false);
        setLoadingUser(false);
        datadogRum.clearUser();
    };

    const refreshUserByAuthenticationState = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        async (setToLoggedIn: any) => {
            setLoadingUser(true);

            /**
             * Since we are logging out, we want to clear the apollo cache of the
             * user's data.
             *
             * Using following method can break mutations that execute immediately after it...
             * client.cache.reset();
             *
             * It appears to reset so hard, mutations immediately after it can fail
             * which do not fail with client.resetStore();
             * Adding client.stop() prevents error...
             * "Store reset while query was in flight (not completed in link chain)"
             * */

            client.stop();
            await client.resetStore();

            if (!setToLoggedIn) {
                clearUser();
                return;
            }

            try {
                const { data: userLoggedInResponse } = await client.query({
                    query: CHECK_USER_LOGGED_IN,
                });
                if (!userLoggedInResponse?.getUserProfile) {
                    clearUser();
                    return;
                }
            } catch (err) {
                ErrorService.captureError(err as Error);
                clearUser();
                return;
            }

            try {
                const { data } = await client.query({
                    query: GET_USER_ACCOUNT_INFO_DATA,
                });

                if (
                    !data ||
                    !data?.getUserProfile ||
                    !data?.getUserProfile?.id
                ) {
                    clearUser();
                } else {
                    setUser(data.getUserProfile);
                    setUserAccountInfo(data);
                    setIsLoggedIn(true);
                    datadogRum.setUser({
                        id: `${data.getUserProfile.id}`,
                        email: data.getUserProfile.email ?? undefined,
                        name: `${data.getUserProfile.first_name} ${data.getUserProfile.last_name}`,
                        city: data.getUserProfile.location?.city_id
                            ? `${data.getUserProfile.location?.city_id}`
                            : undefined,
                    });
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (e: any) {
                if (e.message.includes('UNAUTHORIZED')) {
                    clearUser();
                }
            }

            setLoadingUser(false);
        },
        [client],
    );

    useEffect(() => {
        const userProfile = userAccountInfo?.getUserProfile;
        const userSubscription =
            userAccountInfo?.getUserMembership &&
            userAccountInfo?.getUserMembership?.length > 0
                ? userAccountInfo?.getUserMembership[0]
                : undefined;
        if (userProfile) {
            const { id, ...traits } = userProfile;
            SegmentIdentify(id, {
                ...traits,
                member_status: userSubscription?.status,
            });
        }
    }, [
        router.asPath,
        userAccountInfo?.getUserProfile,
        userAccountInfo?.getUserMembership,
    ]);

    // Refresh user state if user for logged in user
    const refreshUser = useCallback(async () => {
        try {
            await refreshUserByAuthenticationState(true);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
            console.error(e.message);
        }
    }, [refreshUserByAuthenticationState]);

    // Refresh user state if user for logged out user
    const refreshUserLoggedOut = useCallback(async () => {
        try {
            await refreshUserByAuthenticationState(false);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
            console.error(e.message);
        }
    }, [refreshUserByAuthenticationState]);

    const loadUser = async () => {
        if (!isLoggedIn) {
            await refreshUser();
            setLoadingAuth(false);
        }
    };

    // This useEffects loads the user on first load when browser is refreshed
    useEffect(() => {
        loadUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AuthDispatchContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{ refreshUser, refreshUserLoggedOut }}
        >
            <AuthContext.Provider
                // eslint-disable-next-line react/jsx-no-constructed-context-values
                value={{
                    isLoggedIn,
                    userProfile: user,
                    userAccountInfo,
                    authLoading,
                }}
            >
                {children}
            </AuthContext.Provider>
        </AuthDispatchContext.Provider>
    );
};

export { AuthProvider, useAuthContext, useAuthDispatchContext };
