export const getPreviousDay = (day: string) => {
    const daysOfWeek = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ];
    const index = daysOfWeek.indexOf(day);

    if (index === -1) {
        return null; // Invalid day input
    }

    let previousDayIndex = index - 1;
    if (previousDayIndex < 0) {
        previousDayIndex = daysOfWeek.length - 1; // Wrap around to the last day of the week
    }

    return daysOfWeek[previousDayIndex];
};
