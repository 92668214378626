import { OrderIntentStatus } from '@mlkmncode/common';
import { partition } from 'lodash';

import { AutopilotProduct } from '@contexts/AutopilotContext';
import { RefillsListQuery } from '@gql/graphql';
import {
    getLabelForRefillType,
    getRefillTypeByIndex,
    isOrderIntentDisabled,
    isOrderIntentEditable,
    isOrderIntentEmpty,
    isOrderIntentSkipped,
    isOrderIntentUnskippable,
    sumOrderIntentQuantity,
} from '@scenes/Dashboard/Refills/utils';
import { formatSellableQuantity } from '@utilities/quantity';

import {
    ProductForRefills,
    RefillsOrderIntent,
    ServiceProductForRefills,
} from './types';

/**
 * Maps a user bundle product into the form of an order intent product.
 *
 * We do this so we can have autopilot products in the 'Add from Autopilot' section.
 * This section is comprised of order intent products and autopilot products.
 * To distinguish between the two, we negate the id of the autopilot product.
 *
 * @param userBundleProduct
 * @returns
 */
export function mapUserBundleProductToOrderIntentProduct(
    userBundleProduct: AutopilotProduct,
): RefillsListQuery['orderIntents'][0]['orderIntentProducts'][0] {
    return {
        sellableEntityVariationId: userBundleProduct.product!.id,
        product: userBundleProduct.product,

        // We negate the id to avoid conflicts with the order intent product ids.
        // This ID is ultimately used to identify the product in the Apollo cache.
        // We perform optimistic updates based on cache keys, so uniqueness of the IDs
        // is important to ensure we are updating the correct product.
        id: -1 * userBundleProduct.id,
        quantity: 0,
    };
}

/**
 * Partitions given order intent products and user bundle products in three groups:
 * 1. serviceProducts - Includes all order intent products and user bundle products that are services
 * 2. products - Includes all order intent products that are products with quantity > 0
 * 3. emptyProducts - Includes all order intent products with quantity === 0 and user bundle products
 *
 * @param orderIntentProducts
 * @param userBundleProducts
 * @returns
 */
export function partitionProductsAndServices(
    orderIntentProducts: RefillsListQuery['orderIntents'][0]['orderIntentProducts'],
    userBundleProducts: AutopilotProduct[],
) {
    const [refillServiceProducts, allProducts] = partition(
        orderIntentProducts ?? [],
        (product) => product.product?.service_type,
    );
    const serviceProductsFromAutopilotNotInRefills = userBundleProducts
        ?.filter(
            (userBundleProduct) =>
                userBundleProduct.product?.service_type &&
                !refillServiceProducts.find(
                    (serviceProduct) =>
                        serviceProduct.product?.id ===
                        userBundleProduct.product?.id,
                ),
        )
        .map(mapUserBundleProductToOrderIntentProduct);
    const serviceProducts = refillServiceProducts.concat(
        serviceProductsFromAutopilotNotInRefills,
    );

    const [refillProducts, emptyRefillProducts] = partition(
        allProducts,
        (product) => product.quantity > 0,
    );

    const refillProductSevIds = new Set<number>(
        refillProducts
            .map((product) => product.sellableEntityVariationId)
            .filter((id) => id !== undefined),
    );
    const emptyRefillProductSevIds = new Set<number>(
        emptyRefillProducts
            .map((product) => product.sellableEntityVariationId)
            .filter((id) => id !== undefined),
    );

    const emptyProductsFromAutopilotNotInRefills = (userBundleProducts ?? [])
        .filter(
            (userBundleProduct) =>
                userBundleProduct.product &&
                !refillProductSevIds.has(userBundleProduct.product.id) &&
                !emptyRefillProductSevIds.has(userBundleProduct.product.id) &&
                !userBundleProduct.product.service_type,
        )
        .map(mapUserBundleProductToOrderIntentProduct);

    const emptyProducts = emptyRefillProducts.concat(
        emptyProductsFromAutopilotNotInRefills,
    );

    return {
        products: refillProducts,
        emptyProducts,
        serviceProducts: serviceProducts ?? [],
    };
}

function mapServiceProducts(
    orderIntentProducts: RefillsListQuery['orderIntents'][0]['orderIntentProducts'],
    isSkipped: boolean,
): ServiceProductForRefills[] {
    return (orderIntentProducts ?? []).reduce<Array<ServiceProductForRefills>>(
        (products, orderIntentProduct) => {
            const { product } = orderIntentProduct;
            return product
                ? products.concat({
                      id: product.id,
                      name: product.name,
                      orderIntentProductId: orderIntentProduct.id,
                      price: product.subscription_price ?? 0,
                      size: formatSellableQuantity({
                          sellableCount: product.sellable_count ?? 0,
                          sellableQuantity: product.sellable_qty ?? 0,
                          sellableUnit: product.sellable_unit,
                      }),
                      sellableCount: product.sellable_count ?? 0,
                      sellableQuantity: product.sellable_qty ?? 0,
                      sellableUnit: product.sellable_unit,
                      checked: isSkipped
                          ? false
                          : orderIntentProduct.quantity > 0,
                  })
                : products;
        },
        [],
    );
}

function mapProducts(
    orderIntentProducts: RefillsListQuery['orderIntents'][0]['orderIntentProducts'],
): ProductForRefills[] {
    return (orderIntentProducts ?? []).reduce<Array<ProductForRefills>>(
        (products, orderIntentProduct) => {
            const { product } = orderIntentProduct;
            const retailPrice =
                product?.retail_price &&
                product?.price &&
                product.price < product.retail_price
                    ? product.retail_price
                    : null;

            return product
                ? products.concat({
                      id: product.id,
                      name: product.name,
                      orderIntentProductId: orderIntentProduct.id,
                      price: product.price ?? 0,
                      size: formatSellableQuantity({
                          sellableCount: product.sellable_count ?? 0,
                          sellableQuantity: product.sellable_qty ?? 0,
                          sellableUnit: product.sellable_unit,
                      }),
                      sellableCount: product.sellable_count ?? 0,
                      sellableQuantity: product.sellable_qty ?? 0,
                      sellableUnit: product.sellable_unit,
                      retailPrice,
                      imageUrl: product.images?.sm ?? '',
                      quantity: orderIntentProduct.quantity,
                      topLevelCategory:
                          product.top_level_category?.display_name,
                  })
                : products;
        },
        [],
    );
}

export function mapOrderIntents(
    orderIntents: RefillsListQuery['orderIntents'],
    autopilotProducts: AutopilotProduct[],
): RefillsOrderIntent[] {
    return orderIntents
        .slice(0, 3) // Ensure we only ever show 3
        .map((orderIntent, index) => {
            const productImages = (orderIntent.orderIntentProducts ?? [])
                .filter(({ product }) => !product?.service_type)
                .reduce<
                    Array<string>
                >((images, orderIntentProduct) => images.concat(orderIntentProduct.quantity > 0 && orderIntentProduct.product?.images?.sm ? orderIntentProduct.product.images.sm : []), []);

            const itemCount = sumOrderIntentQuantity(
                orderIntent.orderIntentProducts,
            );

            const orderIntentStatus = orderIntent.status as OrderIntentStatus;

            const isUnskippable = isOrderIntentUnskippable(
                orderIntentStatus,
                orderIntent.lockedAt,
            );
            const isSkipped = isOrderIntentSkipped(orderIntentStatus);
            const isEditable = isOrderIntentEditable(
                orderIntentStatus,
                orderIntent.lockedAt,
            );
            const isDisabled = isOrderIntentDisabled(orderIntentStatus);
            const isEmpty = isOrderIntentEmpty(itemCount);
            const isLocked = !!orderIntent.lockedAt;

            const { products, emptyProducts, serviceProducts } =
                partitionProductsAndServices(
                    orderIntent?.orderIntentProducts,
                    autopilotProducts ?? [],
                );
            const serviceProductsMapped = mapServiceProducts(
                serviceProducts,
                isOrderIntentSkipped(orderIntentStatus),
            );
            const productsMapped = mapProducts(products);
            const emptyProductsMapped = mapProducts(emptyProducts);
            const title = getLabelForRefillType(getRefillTypeByIndex(index));
            const cost = {
                subtotal: orderIntent.cost?.subtotal ?? 0,
                estimatedTotal: orderIntent.cost?.estimatedTotal ?? 0,
                credit: orderIntent.cost?.credit ?? 0,
                coupon: orderIntent.cost?.coupon ?? 0,
                estimatedTax: orderIntent.cost?.estimatedTax ?? 0,
            };

            return {
                cost,
                emptyProducts: emptyProductsMapped,
                expectedDeliveryDate: orderIntent.expectedDeliveryDate,
                id: orderIntent.id,
                isDisabled,
                isEditable,
                isEmpty,
                isSkipped,
                isUnskippable,
                isLocked,
                itemCount,
                minimumOrderValueThreshold:
                    orderIntent.minimumOrderValueThreshold,
                productImages,
                products: productsMapped,
                refillType: getRefillTypeByIndex(index),
                serviceProducts: serviceProductsMapped,
                status: orderIntentStatus,
                subtotal: orderIntent.cost?.subtotal ?? 0,
                title,
                total: orderIntent.cost?.estimatedTotal ?? 0,
            };
        });
}
