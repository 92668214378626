import 'core-js/stable';

import { datadogRum } from '@datadog/browser-rum';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { SnackbarProvider } from 'notistack';
import posthog from 'posthog-js';
import React, { useEffect, useRef, useState } from 'react';
import 'regenerator-runtime/runtime';

import {
    ErrorToast,
    InfoToast,
    SuccessLightToast,
    SuccessToast,
    ToastNotificationWithCTA,
} from '@components/Notification';
import { ErrorBoundary } from '@components/errorBoundary/errorBoundary';
import { GoogleMapsProvider } from '@components/googleMaps';
import PostHogProviderWrapper from '@components/posthog';
import { AlertBannerProvider } from '@contexts/AlertBannerContext';
import { AuthProvider } from '@contexts/AuthContext';
import { AutopilotProvider } from '@contexts/AutopilotContext';
import { CuesProvider } from '@contexts/CueContext';
import { ProductSheetProvider } from '@contexts/ProductSheetContext';
import { ProductTrackingProvider } from '@contexts/ProductTrackingContext';
import { RefillCartProvider } from '@contexts/RefillCartContext';
import { RefillProvider } from '@contexts/RefillsContext';
import { UserDashboardProvider } from '@contexts/UserDashboardContext';
import useSiteArea, { SITE_AREA } from '@custom-hooks/useSiteArea';
import { DashboardContextProvider } from '@scenes/Dashboard/context';
import TrThemeOptions from '@theme/TrThemeOptions';
import { SegmentPageView, parseUtm } from '@utilities/analytics';
import { GOOGLE_TAG_MANAGER_ID } from '@utilities/common';
import { FLAGS } from '@utilities/constants/featureFlags';

import ApolloClient from '../apollo';
import '../styles/_geosuggest.scss';
import '../styles/global.scss';

const ConditionalProvider = ({
    children,
    conditionSiteArea,
    ContextProvider,
}) => {
    if (conditionSiteArea) {
        return <ContextProvider>{children}</ContextProvider>;
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
};

// Load Stripe.js asynchronously to collect card details via Stripe Elements
// Only initialize in browser
const stripePromise =
    typeof window !== 'undefined' ? loadStripe(CONFIG.stripe.publicKey) : null;
// Define webfonts that will be available within Stripe Elements components
const stripeOptions = {
    fonts: [
        {
            family: 'HelveticaNeueLT Std',
            weight: 'normal',
            src: `url(${CONFIG.appUrl}/fonts/HelveticaNeueLTStd-Roman.woff2), url(${CONFIG.appUrl}/fonts/HelveticaNeueLTStd-Roman.woff)`,
        },
    ],
};

function isAuthenticatedPath(path) {
    return ['dashboard', 'discover', 'collection'].some((str) =>
        path.includes(str),
    );
}

const App = ({ Component, pageProps }) => {
    // Use ref to store previous URL for Segment page view
    const previousUrlRef = useRef(undefined);
    const [currentPath, setCurrentPath] = useState(null);
    const [areAnalyticsInitialized, setAreAnalyticsInitialized] =
        useState(false);
    const siteArea = useSiteArea();
    const router = useRouter();
    useEffect(() => {
        if (currentPath !== router.asPath) {
            setCurrentPath(router.asPath);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router]);

    const showSmartBanner =
        typeof window === 'object'
            ? isAuthenticatedPath(window.location.pathname)
            : false;

    useEffect(() => {
        if (currentPath !== null) {
            SegmentPageView(
                currentPath,
                {
                    referrer: previousUrlRef.current,
                },
                {
                    context: {
                        ...parseUtm(currentPath)?.context,
                        page: {
                            referrer: previousUrlRef.current,
                        },
                    },
                },
            );

            // This covers most active use cases, and other events can leverage this referrer in sessionStorage
            // Use sessionStorage over localStorage to avoid having to clear it correctly
            if (previousUrlRef.current) {
                sessionStorage.setItem('referrer', previousUrlRef.current);
            }

            // We are only pull the referrer from the document on the first page load
            // This logic works because we are running in a SPA. If we are doing SSR
            // We can remove the logic below since we will always have a referrer from server
            if (!previousUrlRef.current && document.referrer) {
                previousUrlRef.current = document.referrer;
            } else {
                previousUrlRef.current = window.location.href;
            }
        }
    }, [currentPath]);

    useEffect(() => {
        if (CONFIG.datadog.enabled) {
            datadogRum.init({
                applicationId: CONFIG.datadog.applicationId,
                clientToken: CONFIG.datadog.clientToken,
                site: CONFIG.datadog.site,
                service: CONFIG.datadog.serviceName,
                env: CONFIG.env,
                version: CONFIG.release,
                sessionSampleRate: 100,
                sessionReplaySampleRate: 20,
                trackUserInteractions: true,
                trackResources: true,
                trackLongTasks: true,
                defaultPrivacyLevel: 'mask-user-input',
                allowedTracingUrls: [CONFIG.apiUrl],
            });
        }
    }, []);

    const isMobileApp =
        typeof window === 'object'
            ? window.navigator.userAgent.includes('TheRoundsIOSApp')
            : false;

    const isAnalyticsEnabled = isMobileApp
        ? window.navigator.userAgent.includes('Tracking=1')
        : true;

    /* When: areAnalyticsInitialized is true (Segment initialized)
     * Then: initialize Posthog without using the ready() callback
     *       The callback doesn't fire when there are failed Segment integrations
     *       but we want it to initialize regardless.
     */
    useEffect(() => {
        if (!areAnalyticsInitialized || !window.analytics) {
            return;
        }
        posthog.init(CONFIG.posthog.apiKey, {
            api_host: CONFIG.posthog.apiHost,
            autocapture: true,
            capture_pageview: false,
            loaded: (posthogClient) => {
                if (
                    CONFIG.env !== 'prod' ||
                    router.query.debugPostHog === 'true'
                ) {
                    posthogClient.debug();
                }
            },
            segment: window.analytics,
            ui_host: 'us.posthog.com',
            bootstrap: {
                featureFlags: {
                    [FLAGS.REFILL_RECOMMENDATIONS.id]: 'unknown',
                    [FLAGS.ONE_TIME_ADD.id]: 'unknown',
                    [FLAGS.DISABLE_COST_PER_UNIT.id]: 'unknown',
                },
            },
        });
        window.analytics.page();
    }, [areAnalyticsInitialized, router.query.debugPostHog]);

    /**
     * When: The page loads
     *
     * Then: Load Segment analytics.
     */
    useEffect(() => {
        const segmentCdnUrl =
            CONFIG.segment.cdnUrl ?? 'https://analytics.therounds.co';
        const segmentTrackingUrl =
            CONFIG.segment.trackingApiHost ?? 'api.segment.com'; // no https://

        if (areAnalyticsInitialized) {
            return;
        }
        AnalyticsBrowser.load(
            {
                writeKey: CONFIG.segment.writeKey,
                cdnURL: segmentCdnUrl,
            },
            {
                obfuscate: true,
                integrations: {
                    'Segment.io': {
                        apiHost: `${segmentTrackingUrl}/v1`,
                    },
                    'Facebook Pixel': isAnalyticsEnabled,
                    'Google AdWords New': isAnalyticsEnabled,
                },
            },
        ).then((analyticsInstance) => {
            const analytics = analyticsInstance[0];
            window.analytics = analytics;
            setAreAnalyticsInitialized(true);
        });
    }, [
        areAnalyticsInitialized,
        isAnalyticsEnabled,
        isMobileApp,
        router.query.debugPostHog,
    ]);

    const preconnects = (
        isAnalyticsEnabled
            ? [
                  'https://connect.facebook.net',
                  'https://www.facebook.com',
                  'https://ads.nextdoor.com',
                  'https://googleads.g.doubleclick.net',
                  'https://td.doubleclick.net',
                  'https://tags.srv.stackadapt.com',
                  'https://www.googletagmanager.com',
                  CONFIG.segment.cdnUrl ?? 'https://analytics.therounds.co',
              ]
            : []
    ).concat([
        'https://www.google.com',
        'https://maps.googleapis.com',
        CONFIG.apiUrl,
    ]);

    return (
        <>
            <Head>
                <title>The Rounds</title>
                <meta
                    name="viewport"
                    content="width=device-width,initial-scale=1,shrink-to-fit=no, maximum-scale=1"
                />
                {showSmartBanner && (
                    <meta name="apple-itunes-app" content="app-id=6455041489" />
                )}
                {preconnects.map((url) => {
                    return <link rel="preconnect" href={url} key={url} />;
                })}
            </Head>

            {isAnalyticsEnabled && (
                // Setup Google Tag Manager
                <Script id="gtm">{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${GOOGLE_TAG_MANAGER_ID}');`}</Script>
            )}

            {isAnalyticsEnabled && (
                // Convert Experiments script
                <Script
                    id="convertexperiments"
                    type="text/javascript"
                    src="//cdn-4.convertexperiments.com/js/10042582-10043430.js"
                />
            )}

            <ErrorBoundary>
                <Elements stripe={stripePromise} options={stripeOptions}>
                    <PostHogProviderWrapper client={posthog}>
                        <GoogleMapsProvider
                            apiKey={CONFIG?.googleMaps?.placesApiKey}
                        >
                            <StyledEngineProvider injectFirst>
                                <ThemeProvider theme={TrThemeOptions}>
                                    <SnackbarProvider
                                        variant="error"
                                        anchorOrigin={{
                                            horizontal: 'center',
                                            vertical: 'top',
                                        }}
                                        autoHideDuration={3000}
                                        Components={{
                                            successLight: SuccessLightToast,
                                            error: ErrorToast,
                                            info: InfoToast,
                                            success: SuccessToast,
                                            warning: InfoToast,
                                            simpleInfo:
                                                ToastNotificationWithCTA,
                                        }}
                                    >
                                        <ApolloClient>
                                            <AuthProvider>
                                                <ConditionalProvider
                                                    ContextProvider={
                                                        CuesProvider
                                                    }
                                                    conditionSiteArea={
                                                        siteArea ===
                                                        SITE_AREA.DASHBOARD
                                                    }
                                                >
                                                    <ConditionalProvider
                                                        ContextProvider={
                                                            DashboardContextProvider
                                                        }
                                                        conditionSiteArea={
                                                            siteArea ===
                                                            SITE_AREA.DASHBOARD
                                                        }
                                                    >
                                                        <ConditionalProvider
                                                            ContextProvider={
                                                                UserDashboardProvider
                                                            }
                                                            conditionSiteArea={
                                                                siteArea ===
                                                                SITE_AREA.DASHBOARD
                                                            }
                                                        >
                                                            <ConditionalProvider
                                                                ContextProvider={
                                                                    AutopilotProvider
                                                                }
                                                                conditionSiteArea={
                                                                    siteArea ===
                                                                    SITE_AREA.DASHBOARD
                                                                }
                                                            >
                                                                <ConditionalProvider
                                                                    ContextProvider={
                                                                        RefillProvider
                                                                    }
                                                                    conditionSiteArea={
                                                                        siteArea ===
                                                                        SITE_AREA.DASHBOARD
                                                                    }
                                                                >
                                                                    <ConditionalProvider
                                                                        ContextProvider={
                                                                            AlertBannerProvider
                                                                        }
                                                                        conditionSiteArea={
                                                                            siteArea ===
                                                                            SITE_AREA.DASHBOARD
                                                                        }
                                                                    >
                                                                        <ConditionalProvider
                                                                            ContextProvider={
                                                                                ProductTrackingProvider
                                                                            }
                                                                            conditionSiteArea={
                                                                                siteArea ===
                                                                                SITE_AREA.DASHBOARD
                                                                            }
                                                                        >
                                                                            <ConditionalProvider
                                                                                ContextProvider={
                                                                                    RefillCartProvider
                                                                                }
                                                                                conditionSiteArea={
                                                                                    siteArea ===
                                                                                    SITE_AREA.DASHBOARD
                                                                                }
                                                                            >
                                                                                <ConditionalProvider
                                                                                    ContextProvider={
                                                                                        ProductSheetProvider
                                                                                    }
                                                                                    conditionSiteArea={
                                                                                        siteArea ===
                                                                                        SITE_AREA.DASHBOARD
                                                                                    }
                                                                                >
                                                                                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                                                                                    <Component
                                                                                        {...pageProps}
                                                                                    />
                                                                                </ConditionalProvider>
                                                                            </ConditionalProvider>
                                                                        </ConditionalProvider>
                                                                    </ConditionalProvider>
                                                                </ConditionalProvider>
                                                            </ConditionalProvider>
                                                        </ConditionalProvider>
                                                    </ConditionalProvider>
                                                </ConditionalProvider>
                                            </AuthProvider>
                                        </ApolloClient>
                                    </SnackbarProvider>
                                </ThemeProvider>
                            </StyledEngineProvider>
                        </GoogleMapsProvider>
                    </PostHogProviderWrapper>
                </Elements>
            </ErrorBoundary>
        </>
    );
};

export default App;
