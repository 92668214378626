import { sortBy } from 'lodash';

import { PromoCodeDataAction } from '@scenes/Onboarding/types';

export interface DiscountData {
    bannerText: string | null;
    discountText: string | null;
    hasSatisfiedCouponMinimum: boolean | undefined;
}

const getDiscountData = (
    promoCodeDataActions: PromoCodeDataAction[],
    currentOrderIntentTotal: number,
): DiscountData => {
    let discountText = null;
    let bannerText = null;
    let hasSatisfiedCouponMinimum = false;

    const promoCodeDataActionSorted = sortBy(promoCodeDataActions, [
        'discount_type',
    ]);

    const discountParts = [];

    for (let i = 0; i < promoCodeDataActionSorted.length; i += 1) {
        const action = promoCodeDataActionSorted[i];
        const count = action.max_order_usage_count;
        const type = action.discount_type;
        const value = action.discount_value;
        const minimumOrderValue = action.min_required_order_value;

        // If there is a minimum order value, check if the current order intent total is greater than
        // or equal to it. If it is, display the minimum order value banner text.
        if (minimumOrderValue && type === 'order_flat') {
            hasSatisfiedCouponMinimum =
                currentOrderIntentTotal >= minimumOrderValue;

            bannerText = hasSatisfiedCouponMinimum
                ? `Congrats! $${value} off applied`
                : null;

            if (currentOrderIntentTotal < minimumOrderValue) {
                const difference = minimumOrderValue - currentOrderIntentTotal;
                discountText =
                    difference > value
                        ? `SPEND $${minimumOrderValue} → SAVE $${value}`
                        : `You're only $${difference.toFixed(2)} away from $${value} off`;
                return {
                    discountText,
                    bannerText,
                    hasSatisfiedCouponMinimum,
                };
            }
        }

        // If there is no minimum order value, display the chain of discount strings.
        if (type === 'membership_months') {
            discountParts.push(
                `${value} ${value > 1 ? 'free months' : 'free month'}`,
            );
        } else if (type === 'order_flat') {
            let message =
                count === 1
                    ? 'off your first order'
                    : `off your first ${count} orders`;
            if (minimumOrderValue && minimumOrderValue > 0) {
                message += ` over $${minimumOrderValue}`;
            }
            discountParts.push(`$${value} ${message}`);
        } else if (type === 'order_percent') {
            let message =
                count === 1
                    ? 'off your first order'
                    : `off your first ${count} orders`;
            if (minimumOrderValue && minimumOrderValue > 0) {
                message += ` over $${minimumOrderValue}`;
            }
            discountParts.push(`${Math.floor(value)}% ${message}`);
        }
    }

    if (discountParts.length) {
        discountText = discountParts.join(' + ');
    }

    return {
        bannerText,
        discountText,
        hasSatisfiedCouponMinimum,
    };
};

export { getDiscountData };
